import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useFetch from "../../hooks/use-fetch.hook";
import { Heading, SubHeading } from "../../components/headings/headings";
import Body, { CustomHeightContainer } from "../../components/body/body";
import SelectInputOnly from "../../components/select-input-only/select-input-only";
import Label from "../../components/label/label";
import Input from "../../components/input/input";
import Footer from "../../components/footer/footer";
import { INDIAN_STATES } from "../../utils/india-states";
import { getCities } from "../../utils/india-state-cities";
import { blobToBase64 } from "../../utils/convert-to-base64";
import { useDispatch, useSelector } from "react-redux";
import { updateCliamShopDetails } from "../../lib/redux/slices/claim-shop.slice";
import { toast } from "react-toastify";
import {
  getErrorAddDetailsPage,
  getErrorMobileNumber,
} from "../../utils/validation";
import Button from "../../components/button/button";
import AxiosInstance from "../../lib/axios/axios-instance";
import CloseDarkIcon from "../../assets/close-dark.svg";
import { updateValidMobileNumber } from "../../lib/redux/slices/valid-mobile-number.slice";
import { SHOP_TYPES } from "../../utils/shop-types";
import PhoneNumberInput from "../../components/phone-number-input";


const axiosInstance = AxiosInstance.getInstance();

const ReSendOtpButton = ({ sendOtp, isValidating, timer, setTimer }) => {
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    if (timer === 0) {
      clearInterval(myInterval);
    }

    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Button
      bgColor="transparent"
      textColor="#110C22"
      onClick={sendOtp}
      disabled={isValidating || timer > 0}
    >
      {isValidating
        ? "Sending..."
        : `Re-send ${timer > 0 ? `in` : "OTP"} ${
            timer > 0 ? `(${timer})` : ""
          }`}
    </Button>
  );
};

const KYCVerificationModal = ({
  onClose,
  shopOwnerContactNumber,
  onSuccessfulVerification,
  isValidating,
  sendOtp,
  otpSent,
  timer,
  setTimer,
}) => {
  const [payload, setPayload] = useState({
    shopOwnerContactNumber: shopOwnerContactNumber,
  });
  const [otp, setOtp] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const dispatch = useDispatch();

  const verifyOtp = async () => {
    setIsVerifying(true);
    if (!otp || !otp.trim()) {
      setIsVerifying(false);
      return toast.error("Please provide a valid OTP.");
    }

    try {
      await axiosInstance.patch(`/otp/verify-otp`, {
        otp: otp,
        mobileNumber: payload.shopOwnerContactNumber,
      });

      onClose();
      setIsVerifying(false);
      dispatch(
        updateValidMobileNumber({
          validMobileNumber: payload.shopOwnerContactNumber,
        })
      );
      onSuccessfulVerification();
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message?.toString());
      setIsVerifying(false);
    }
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex mt-36 md:mt-0 md:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mt-3 sm:text-left">
                <div>
                  <h3
                    className="text-sm font-semibold leading-6"
                    id="modal-title"
                  >
                    KYC Verification
                  </h3>
                  <SubHeading>Enter details to validate</SubHeading>
                </div>
                <div>
                  <button className="p-1 text-black" onClick={onClose}>
                    <img src={CloseDarkIcon} alt="close-icon" />
                  </button>
                </div>
              </div>

              <div className="mt-4 flex flex-col gap-4">
                {/* <Input
                  name="shopOwnerContactNumber"
                  label="Enter Mobile number"
                  type="text"
                  value={payload.shopOwnerContactNumber}
                  disabled={true}
                /> */}
                <PhoneNumberInput
                  name="shopOwnerContactNumber"
                  label="Enter Mobile number"
                  value={payload.shopOwnerContactNumber}
                  disabled={true}
                /> 

                {otpSent && (
                  <Input
                    label="OTP"
                    name="otp"
                    type="text"
                    onChange={(e) => setOtp(e.target.value)}
                    minLength={6}
                    maxLength={6}
                    disabled={isVerifying || isValidating}
                  />
                )}
              </div>
            </div>

            {otpSent ? (
              <div className="p-6 flex gap-2 items-center justify-end">
                <ReSendOtpButton
                  sendOtp={sendOtp}
                  isValidating={isVerifying || isValidating}
                  timer={timer}
                  setTimer={setTimer}
                />
                <Button
                  onClick={verifyOtp}
                  disabled={isVerifying || isValidating}
                >
                  {isVerifying ? "Verifying..." : "Verify OTP"}
                </Button>
              </div>
            ) : (
              <div className="p-6 flex gap-2 items-center justify-end">
                <Button
                  bgColor="transparent"
                  textColor="#110C22"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button onClick={sendOtp} disabled={isValidating}>
                  {isValidating ? "Sending..." : "Send OTP"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectInput = ({ label, options, value, onChange, name }) => {
  return (
    <div className="w-full">
      <Label>{label}</Label>
      <div className="w-full">
        <SelectInputOnly
          name={name}
          value={value}
          onChange={onChange}
          options={options}
        />
      </div>
    </div>
  );
};

const MultiSelectInput = ({ label, options, value, onChange, selectRef }) => {
  return (
    <div className="w-full">
      <Label>{label}</Label>
      <div className="w-full">
        <Select
          ref={selectRef}
          value={value}
          onChange={onChange}
          options={options}
          isMulti={true}
          styles={{
            control: (base) => {
              return {
                ...base,
                borderWidth: 1,
                borderColor: "#e5e7eb",
                ":hover": {
                  borderColor: "#e5e7eb",
                  borderWidth: 1,
                },
                ":active": {
                  borderColor: "#e5e7eb",
                  borderWidth: 1,
                },
              };
            },
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            borderWidth: 1,
            colors: {
              ...theme.colors,
              primary: "#e5e7eb",
            },
          })}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
        />
      </div>
    </div>
  );
};

const FileUploader = ({ handleFile, name }) => {
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();

  const file = useSelector((state) => state.claimShop?.[name]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const addFile = async (value) => {
    const base64Url = await blobToBase64(value);
    dispatch(
      updateCliamShopDetails({
        key: name,
        value: base64Url,
      })
    );
  };

  const isImageType = (fileType) => {
    if (!fileType) return false;

    const type = fileType.split("/")[0];
    console.log(type);
    if (type !== "image") return false;

    return true;
  };

  const handleChange = (event) => {
    const fileUploaded = event?.target?.files[0];
    if (!isImageType(fileUploaded.type)) {
      return toast.error(`Please provide cancelled cheque image.`);
    }

    if (fileUploaded) {
      addFile(fileUploaded);
    }
  };
  return (
    <div>
      <button
        onClick={handleClick}
        className="w-full border rounded-lg py-2 px-3 font-medium text-sm"
      >
        {file ? "Image Uploaded" : "Upload Image"}
      </button>
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: "none" }}
        accept="image/png, image/jpeg, image/heic, image/webp"
      />
    </div>
  );
};

export const ShowStateOptions = ({ options }) => {
  if (!options) return null;
  return options.map((option, idx) => (
    <option key={option} value={idx}>
      {option[idx]}
    </option>
  ));
};

const AddDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [timer, setTimer] = useState(60);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const claimShopDetails = useSelector((state) => state.claimShop);
  const validMobileNumberDetails = useSelector(
    (state) => state.validMobileNumber
  );

  const updateKeyValue = (key, value) => {
    dispatch(
      updateCliamShopDetails({
        key,
        value,
      })
    );
  };

  const categoriesResponse = useFetch({
    url: "/categories/filter",
  });

  const [subCategoriesData, setSubCategoriesData] = useState(null);
  const [subCategoriesLoading, setSubCategoriesLoading] = useState(true);
  const subCategoriesSelectInput = useRef(null)

  useEffect(() => {
    subCategoriesSelectInput.current?.clearValue();
    updateKeyValue("subCategories", [])
    setSubCategoriesData(null)
    if(!claimShopDetails?.categories || !claimShopDetails?.categories?.length) return 

    async function getData() {
      try {
      const categoryIds = claimShopDetails.categories.map((category) => category.id)
      const response = await axiosInstance.get(`/sub-categories/filter`,{ params: { categoryIds: categoryIds }})

      setSubCategoriesData(response.data);
      setSubCategoriesLoading(false);
    } catch (error) {
      console.error(error);
      setSubCategoriesData(null);
      setSubCategoriesLoading(false);
    }
  }

  getData()
  },[claimShopDetails.categories])

  const onCancel = () => {
    navigate("/instructions");
  };

  const onNext = () => {
    const error = getErrorAddDetailsPage(claimShopDetails);

    if (error) {
      return toast.error(error);
    }

    if (
      validMobileNumberDetails?.validMobileNumber ===
      claimShopDetails.shopOwnerContactNumber
    ) {
      return onSuccessfulVerification();
    }

    displayModal();
  };

  const onSuccessfulVerification = () => {
    navigate("/add-store-front-picture");
  };

  const onChange = (event) => {
    updateKeyValue(event.target.name, event.target.value);
  };

  const sendOtp = async () => {
    setIsValidating(true);
    const error = getErrorMobileNumber({
      shopOwnerContactNumber: claimShopDetails.shopOwnerContactNumber,
    });

    if (error) {
      setIsValidating(false);
      return toast.error(error);
    }

    try {
      await axiosInstance.post("/otp/verification", {
        mobileNumber: claimShopDetails.shopOwnerContactNumber,
      });

      setIsValidating(false);
      setOtpSent(true);
      toast.success("OTP sent successfully.");
      setTimer(60);
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.message?.toString());
      setIsValidating(false);
    }
  };

  const displayModal = () => setShowModal(true);
  const hideModal = () => setShowModal(false);

  if (showModal) {
    return (
      <KYCVerificationModal
        shopOwnerContactNumber={claimShopDetails.shopOwnerContactNumber}
        onClose={hideModal}
        onSuccessfulVerification={onSuccessfulVerification}
        isValidating={isValidating}
        otpSent={otpSent}
        timer={timer}
        setTimer={setTimer}
        sendOtp={sendOtp}
      />
    );
  }

  return (
    <>
      <CustomHeightContainer>
        <Body>
          <div className="mb-3">
            <Heading>Claim Shop - Add details</Heading>
            <div className="my-2">
              <SubHeading>
                Please enter shop address to verify. The address you provide
                here will be hidden from the public.
              </SubHeading>
            </div>

            <div className="flex gap-10 w-full mt-7 flex-col md:flex-row">
              <div className="md:w-1/2 flex flex-col gap-4">
                <Input
                  label="Enter Shop name"
                  name="name"
                  value={claimShopDetails.name}
                  type="text"
                  onChange={onChange}
                />

                <Input
                  label="About Shop (Short Intro)"
                  name="intro"
                  value={claimShopDetails.intro}
                  type="text"
                  onChange={onChange}
                />

                <div className="w-full flex gap-6 md:flex-row flex-col">
                  <div className="md:w-1/2 w-full">
                    <SelectInput
                      label="State"
                      name="state"
                      value={claimShopDetails.state}
                      onChange={onChange}
                      options={INDIAN_STATES}
                    />
                  </div>

                  <div className="md:w-1/2 w-full">
                    <SelectInput
                      label="City"
                      name="city"
                      value={claimShopDetails.city}
                      onChange={onChange}
                      options={getCities(claimShopDetails?.state)}
                    />
                  </div>

                  <div className="md:w-1/2 w-full">
                    <Input
                      label="Pin"
                      name="pin"
                      value={claimShopDetails.pin}
                      type="text"
                      onChange={onChange}
                    />
                  </div>
                </div>

                <Input
                  label="Address"
                  name="address"
                  value={claimShopDetails.address}
                  type="text"
                  onChange={onChange}
                />

                <SelectInput
                  label="Shop Type"
                  name="shopType"
                  value={claimShopDetails.shopType}
                  onChange={onChange}
                  options={SHOP_TYPES}
                />

                <MultiSelectInput
                  label=" Category"
                  options={
                    !categoriesResponse.isLoading && categoriesResponse?.data
                      ? categoriesResponse.data
                      : []
                  }
                  value={claimShopDetails.categories}
                  onChange={(value) => updateKeyValue("categories", value)}
                />

                <MultiSelectInput
                  selectRef={subCategoriesSelectInput}
                  label="Sub - Category"
                  options={
                    !subCategoriesLoading &&
                    subCategoriesData
                      ? subCategoriesData
                      : []
                  }
                  value={claimShopDetails.subCategories?.length ? claimShopDetails.subCategories : undefined}
                  onChange={(value) => updateKeyValue("subCategories", value)}
                />
              </div>

              <div className="md:w-1/2 flex flex-col gap-4">
                <Input
                  label="Shop Owner’s Name"
                  name="shopOwnerName"
                  value={claimShopDetails.shopOwnerName}
                  type="text"
                  onChange={onChange}
                />

                {/* <Input
                  label="Shop Owner Contact Number (Add registered number)"
                  name="shopOwnerContactNumber"
                  value={claimShopDetails.shopOwnerContactNumber}
                  type="text"
                  onChange={onChange}
                /> */}

                <PhoneNumberInput
                  name="shopOwnerContactNumber"
                  label="Shop Owner Contact Number (Add registered number)"
                  value={claimShopDetails.shopOwnerContactNumber}
                  onChange={(value)=> updateKeyValue('shopOwnerContactNumber', value)}
                /> 

                <div className="w-full">
                  <Label>
                    Shop Owner’s Bank Details* (Your bank details preferred)
                  </Label>
                  <div className="w-full flex flex-col md:flex-row items-center justify-between md:gap-7 gap-3">
                    <div className="w-full md:w-3/4 border rounded-lg py-2 px-3 text-[#8D8A95] font-normal text-sm">
                      Upload cancelled cheque photo
                    </div>
                    <div className="w-full md:w-48">
                      <FileUploader name="shopOwnerCancelledChequeImage" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Body>
        <Footer onNext={onNext} onCancel={onCancel} />
      </CustomHeightContainer>
      
    </>
  );
};

export default AddDetails;
