import Label from "../label/label";
import PhoneInput from 'react-phone-number-input'
import './styles.scss'

const PhoneNumberInput = ({ label, name, value, type, onChange, disabled = false }) => {
  return (
    <div className="w-full">
      <Label>{label}</Label>
      <div className="w-full">
      <PhoneInput
          disabled={disabled}
          className="w-full border rounded-lg py-2 px-3 font-normal text-sm"
          defaultCountry="IN"
          value={value}
          countries={["IN"]}
          onChange={onChange}
          name={name}
      />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
