import Button from "../../components/button/button";
import { useNavigate } from "react-router-dom";
import { FreePlanButtons, Plans, Plan, HeaderComponent } from '../subscribe/subscribe'


const PremiumPlanButtons = () => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col gap-3 w-28">
      <Button onClick={() => navigate('/instructions')} bgColor="#0097A7">
        Subscribe Now
      </Button>

    </div>
  );
};

const Data = ({ shopId, onReload }) => {
  return Plans({ shopId, onReload }, FreePlanButtons, PremiumPlanButtons).map((plan) => <Plan plan={plan}/> );
};

const Subscribe = () => {
  return (
    <HeaderComponent>
      <Data />
    </HeaderComponent>
  );
};

export default Subscribe;
